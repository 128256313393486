import { upperFirst, orderBy, uniqBy } from 'lodash-es';
import { LOCATION_FOR_BOT } from '../hooks/useLocationDetect';

export enum Country {
  US = 'USA',
  CA = 'Canada',
}

export interface CityLocation {
  name: string;
  state: string;
  country?: string;
  postalCode?: string;
}

export interface CityData {
  country: string;
  state: string;
  city: string;
  fallback_postal_code: string;
  geo_codes: string[];
}

export interface StateProvince {
  stateCode: string;
  stateName: string;
}

export function getCountryNameByLocale(locale: string): Country {
  switch (locale) {
    case 'en-us':
      return Country.US;
    case 'en-ca':
      return Country.CA;
    case 'fr-ca':
      return Country.CA;
    default:
      return Country.CA;
  }
}

export function getCountryFromLocale(locale: string): string {
  return locale.split('-').pop();
}

export const getInCityName = (state: string, city: string) => {
  const cityName = city
    .split(' ')
    .map((c: string) => c.toLowerCase())
    .join('-')
    .replace("'", '');
  return `${cityName}-${state.toLowerCase()}`;
};

export async function getAllCitiesData(country = 'ca'): Promise<{ cityName: string; state: string }[]> {
  const res = await fetch(`${process.env.NEXT_PUBLIC_FLIPPBACK_API_HOST}/city?country=${country}`);
  const data = await res.json();
  return orderBy(uniqBy(data, 'cityName'), (city) => city.cityName.toLowerCase(), ['asc']);
}

export async function getCityData(location: string): Promise<CityData> {
  const arr = location.split('-');
  const state = arr.pop();
  const city = arr.join(' ');
  const cityName = city.split(' ').join('_');
  const url = `https://flipp.com/data/cities/${state}_${cityName}.json`;
  try {
    const res = await fetch(url);
    const data = await res.json();
    if (data && data.city) {
      return data;
    }
    return null;
  } catch (e) {
    return null;
  }
}

export function getPostalCodeByCity(cityState: string, cityLocations: CityLocation[], locale: string): string {
  const city = extractCityFromLocation(cityState);
  const location = cityLocations.find((item) => {
    return item.name.toLowerCase() === city.toLowerCase();
  });
  return location ? location.postalCode : LOCATION_FOR_BOT[locale].postalCode;
}

export const getCityNameFromLocation = (location: string) => {
  const city = extractCityFromLocation(location);
  return city
    .split(' ')
    .map((c: string) => upperFirst(c))
    .join(' ');
};

export function extractCityFromLocation(location: string): string {
  return location
    .split('-')
    .slice(0, -1)
    .map((item) => upperFirst(item))
    .join(' ');
}

export function extractStateFromLocation(location: string): string {
  return location.split('-').pop().toUpperCase();
}

const getPostalCode = `${process.env.NEXT_PUBLIC_FLIPPBACK_BF}/flipp/location_info/by_ip`;

export { getPostalCode };
