import { ParsedUrlQuery } from 'querystring';
import uniqid from 'uniqid';
import { pick } from 'lodash-es';
import { CityLocation, getInCityName } from './location';
import { slugify } from './slugify';
import { DataJSON } from '../definitions/BackflippDefinitions';

export const NONEXISTENT = -1;
export const ZERO = 0;
export const ONE = 1;
export const TWO = 2;
export const THREE = 3;
export const FOUR = 4;
export const DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;
export const DAY_IN_SECONDS = 24 * 60 * 60;
export const MONTH_IN_SECONDS = 30 * 24 * 60 * 60;

export const TOP_MERCHANTS_COUNT = 20;

export interface RouteProps {
  data: any;
  breadcrumbs: LinkData[];
  merchants: LinkData[];
  cities: any;
}

export type Locale = 'en-ca' | 'en-us' | 'fr-ca';

export interface RouteGetStaticPropsContext extends ParsedUrlQuery {
  slug: string[];
}

export interface MerchantFlyer {
  available_to: number;
  available_from: number;
  language: number;
  state: number;
  flyer_run_id: number;
  hashed_key: string;
  valid_to: number;
  width: number;
  vanilla: number;
  id: number;
  created_at: number;
  tile_path: string;
  image_resolutions: string;
  valid_from: number;
  thumbnails: {
    organic: string;
    premium: string;
  };
  updated_at: number;
  deleted: number;
  image_timestamp: number;
  height: number;
  image_widths: string;
  description: string;
  fsas: string;
  flyer_name: string;
}

export interface Merchant {
  us_based: boolean;
  large_image_path: string;
  id: number;
  name_identifier: string;
  name: string;
}

export interface MerchantCity {
  city: string;
  state: string;
  city_state: string;
  flyers: MerchantFlyer[];
}

export interface MerchantMetadata {
  merchant: Merchant;
  cities: MerchantCity[];
}

export interface LinkData {
  id: string;
  name: string;
  link: string;
  payload?: any;
  imageUrl?: string;
  subLinkList?: LinkData[];
}

export async function getDataByPostalCode(postalCode: string, locale: string): Promise<DataJSON> {
  const url = `${process.env.NEXT_PUBLIC_FLIPPBACK_BF}/flipp/data?locale=${locale}&postal_code=${postalCode}`;
  try {
    const res = await fetch(url, {
      next: { revalidate: 100 },
    });
    const data = await res.json();
    return {
      ...data,
      flyers: data.flyers.map((f: any) => {
        return pick(f, [
          'id',
          'name',
          'merchant',
          'merchant_id',
          'merchant_logo',
          'premium',
          'priority',
          'categories',
          'valid_from',
          'valid_to',
          'available_from',
          'available_to',
          'premium_thumbnail_url',
          'thumbnail_url',
        ]);
      }),
    };
  } catch (e) {
    return null;
  }
}

export async function getCommonFlippData(country: string, locale: string) {
  const [dataStates, dataCities, dataMerchants, dataProducts] = await Promise.all([
    fetch(`${process.env.NEXT_PUBLIC_FLIPPBACK_API_HOST}/state?country=${country}`),
    fetch(`${process.env.NEXT_PUBLIC_FLIPPBACK_API_HOST}/city/top/${locale}`),
    fetch(`${process.env.NEXT_PUBLIC_FLIPPBACK_API_HOST}/merchants/top/${locale}`),
    fetch(`${process.env.NEXT_PUBLIC_FLIPPBACK_API_HOST}/data/categories`),
  ]);
  const states = await dataStates.json();
  const cities = await dataCities.json();
  const merchants = await dataMerchants.json();
  const products = await dataProducts.json();

  return { states, cities, merchants, products };
}

export function getCitiesData(cities: CityLocation[], locale: string): LinkData[] {
  const data = cities.map((item) => {
    return {
      id: uniqid(),
      name: item.name,
      link: `/${locale}/${getInCityName(item.state, item.name)}`,
    };
  });
  return [
    ...data,
    { id: uniqid(), name: 'Location Browser', link: `/${locale}/browser` },
    { id: uniqid(), name: 'Categories Browser', link: `/${locale}/categories` },
  ];
}

export function getTopMerchantsData(data: string[], locale: string): LinkData[] {
  return data.slice(TOP_MERCHANTS_COUNT).map((name: string) => {
    return { id: uniqid(), name: name, link: `/${locale}/stores/${slugify(name)}` };
  });
}

export function fixImageUrl(url: string): string {
  return url.replace('http', 'https');
}
